"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var MapProcessingUtil = _interopRequireWildcard(require("../MapProcessingUtil"));

var config = _interopRequireWildcard(require("../MapProcessing/config"));

var _LocationType = _interopRequireDefault(require("./LocationType"));

var _misc = _interopRequireDefault(require("./misc"));

var _excluded = ["nextGap", "prevGap", "prevAzimuth", "prevDistance"],
    _excluded2 = ["readings", "coordinates", "nextDistance", "nextAzimuth"],
    _excluded3 = ["value", "code"],
    _excluded4 = ["StationId", "comments", "time", "oldStns", "readingReplaced", "reading1", "reading2", "originalReading1", "originalReading2", "ir", "doc", "ss", "flaggedSS", "flaggedStn", "olre", "clock", "interpolatedReading1", "interpolatedReading2", "acOn", "acOff", "gpsType", "triggerType", "docTop", "fileName", "originalComments"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var _validateOtherGpsReadingData = function _validateOtherGpsReadingData(gpsReadingOtherProps) {
  var nextGap = gpsReadingOtherProps.nextGap,
      prevGap = gpsReadingOtherProps.prevGap,
      prevAzimuth = gpsReadingOtherProps.prevAzimuth,
      prevDistance = gpsReadingOtherProps.prevDistance,
      otherProps = _objectWithoutProperties(gpsReadingOtherProps, _excluded);

  _misc["default"].validateEmptyProps('gpsreading', otherProps);
};

var _validateReadingsData = function _validateReadingsData(readingOtherProps) {
  _misc["default"].validateEmptyProps('reading', readingOtherProps);
};

var _validateReadingsValueData = function _validateReadingsValueData(readingsValueOtherProps) {
  _misc["default"].validateEmptyProps('reading.value', readingsValueOtherProps);
}; // Adds other items (like elevation and pdop) to new coordinates when necessary


var _extendInterpolatedCoordinates = function _extendInterpolatedCoordinates(originalCoordinates, newCoordinates) {
  originalCoordinates.forEach(function (originalCoord, i) {
    if (newCoordinates[i] !== undefined) {
      return;
    }

    newCoordinates[i] = originalCoord;
  });
}; // TODO: We should only try to pull DCVG data if surveyType=DCVG


var flattenGpsReadings = function flattenGpsReadings(originalGpsReadings) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _originalGpsReadings = _slicedToArray(originalGpsReadings, 1),
      _originalGpsReadings$ = _originalGpsReadings[0];

  _originalGpsReadings$ = _originalGpsReadings$ === void 0 ? {} : _originalGpsReadings$;
  var code = _originalGpsReadings$.code,
      locationType = _originalGpsReadings$.locationType; // Checks to see if already flattened

  if (code !== undefined || locationType !== undefined) {
    return originalGpsReadings;
  }

  var _options$algorithm = options.algorithm,
      algorithm = _options$algorithm === void 0 ? config.GEO_ALGORITHMS.GEOD : _options$algorithm;
  var flattenedReadings = [];
  var filteredGPSReadings = originalGpsReadings.filter(function (_ref) {
    var _ref$coordinates = _ref.coordinates,
        coordinates = _ref$coordinates === void 0 ? [0, 0] : _ref$coordinates;
    return coordinates.length !== 0;
  });
  filteredGPSReadings.forEach(function (gpsReading, n) {
    var readings = gpsReading.readings,
        originalCoordinates = gpsReading.coordinates,
        nextDistance = gpsReading.nextDistance,
        nextAzimuth = gpsReading.nextAzimuth,
        otherProps = _objectWithoutProperties(gpsReading, _excluded2);

    _validateOtherGpsReadingData(otherProps);

    var isLastItem = n === filteredGPSReadings.length - 1;
    var isSecondLastItem = n === filteredGPSReadings.length - 2;
    readings.forEach(function (reading, i) {
      var value = reading.value,
          code = reading.code,
          otherReadingProps = _objectWithoutProperties(reading, _excluded3);

      _validateReadingsData(otherReadingProps);

      var StationId = value.StationId,
          comments = value.comments,
          time = value.time,
          oldStns = value.oldStns,
          readingReplaced = value.readingReplaced,
          reading1 = value.reading1,
          reading2 = value.reading2,
          originalReading1 = value.originalReading1,
          originalReading2 = value.originalReading2,
          ir = value.ir,
          doc = value.doc,
          ss = value.ss,
          flaggedSS = value.flaggedSS,
          flaggedStn = value.flaggedStn,
          olre = value.olre,
          clock = value.clock,
          interpolatedReading1 = value.interpolatedReading1,
          interpolatedReading2 = value.interpolatedReading2,
          acOn = value.acOn,
          acOff = value.acOff,
          gpsType = value.gpsType,
          triggerType = value.triggerType,
          docTop = value.docTop,
          fileName = value.fileName,
          originalComments = value.originalComments,
          otherReadingValueProps = _objectWithoutProperties(value, _excluded4);

      _validateReadingsValueData(otherReadingValueProps); // combination of "reading" and "reading.value"


      var readingShell = {
        code: code,
        StationId: StationId,
        comments: comments,
        oldStns: oldStns,
        time: time,
        // ON-OFF
        reading1: reading1,
        reading2: reading2,
        originalReading1: originalReading1,
        originalReading2: originalReading2,
        // DCVG
        ir: ir,
        doc: doc,
        ss: ss,
        flaggedSS: flaggedSS,
        flaggedStn: flaggedStn,
        olre: olre,
        clock: clock,
        interpolatedReading1: interpolatedReading1,
        interpolatedReading2: interpolatedReading2,
        acOn: acOn,
        acOff: acOff,
        gpsType: gpsType,
        triggerType: triggerType,
        docTop: docTop,
        fileName: fileName,
        originalComments: originalComments
      };

      _misc["default"].cleanEmptyValues(readingShell); // TODO: We don't want to interpolate readings if they are in the last gps reading


      if (i === 0) {
        // We don't need to interpolate this as there is just the one item
        var newGpsReading = _objectSpread(_objectSpread({}, readingShell), {}, {
          locationType: _LocationType["default"].GPS,
          coordinates: originalCoordinates
        });

        flattenedReadings.push(newGpsReading);
        return;
      }

      var coords;

      if (isLastItem) {
        coords = originalCoordinates;
      } else {
        coords = MapProcessingUtil.calculateInterpolatedPosition(gpsReading.coordinates[1], gpsReading.coordinates[0], nextDistance === undefined ? 0 : nextDistance, nextAzimuth, i, readings.length, {
          algorithm: algorithm
        });
      }

      _extendInterpolatedCoordinates(originalCoordinates, coords);

      if (isSecondLastItem) {
        var lastCoordinates = filteredGPSReadings[filteredGPSReadings.length - 1].coordinates;

        if (lastCoordinates.length === 5 && lastCoordinates[4] === true) {
          coords.splice(4, 0, true);
        }
      }

      var interpolatedGpsReading = _objectSpread(_objectSpread({}, readingShell), {}, {
        locationType: _LocationType["default"].INTERPOLATED,
        coordinates: coords
      });

      flattenedReadings.push(interpolatedGpsReading);
    });
  });
  MapProcessingUtil.updateGPSMeasurements(flattenedReadings);
  return flattenedReadings;
};

var _default = flattenGpsReadings;
exports["default"] = _default;