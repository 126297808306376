/* eslint-disable import/no-cycle */
import ajax from '../../../../scanline/utils/ajax';
import { getAccessToken } from '../../../../scanline/utils/token';

function processDate(d) {
    // If d is null or undefined, return the default Unix epoch date
    if (d === null || d === undefined) {
        return 'Thu Jan 01 1970 00:00:00';
    }

    // If d is a timestamp (a number), convert it to a Date object
    if (typeof d === 'number') {
        d = new Date(d).toISOString();
    }

    // Ensure d is a valid string and remove trailing 'Z'
    if (typeof d === 'string' && d.length > 0) {
        return d.replace(/Z$/, '');
    }

    // If input is invalid, return the default Unix epoch date
    return 'Thu Jan 01 1970 00:00:00';
}

const jobListUtils = {
	getJobs(customer, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/jobs/${encodeURI(customer)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						const jobs = res.jobs.map(j => {
							const {
								ct,
								pm,
								c,
								e,
								l,
								s,
								t,
								st,
								d,
								reportdelivery,
								scan,
								...rest
							} = j;

							return {
								...rest,
								corrosiontechnician: res.corrosionTechnicians[ct],
								customer: res.customers[c],
								projectmanager: res.projectManagers[pm],
								entity: res.entities[e],
								linename: res.lineNames[l],
								status: res.statuses[s],
								subtype: res.subtypes[t],
								surveytype: res.surveyTypes[st],
								reportdelivery: reportdelivery ? reportdelivery : null,
								scanlineenabled: scan ? scan : false,
								end: processDate(d[1]),
								start: processDate(d[0])
							};
						});
						callback(null, { jobs });
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getJobMetadata(jobId, lineName, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/jobMetadata/${encodeURI(jobId)}/${encodeURIComponent(lineName)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getJobsMetadata(jobs, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				'/jobsmetadata',
				JSON.stringify(jobs),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getLines(jobId, lineName, index, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/lines/${encodeURI(jobId)}/${encodeURIComponent(
					lineName
				)}?index=${encodeURI(index)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getZip(job, type, callback) {
		ajax(
			'/zipfiles',
			JSON.stringify({ job, type }),
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			},
			undefined,
			'fieldline'
		);
	},

	downloadFinalReport(job, callback) {
		ajax(
			'/downloadFinalReport',
			JSON.stringify({ job }),
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			},
			undefined,
			'fieldline'
		);
	},

	getCustomers(callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				'/customers',
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	}
};

export default jobListUtils;
