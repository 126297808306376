"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _calculateDistance = require("../MapProcessing/calculateDistance");

var _deriveSkipReadings = function _deriveSkipReadings(convertedReadings) {
  var skipReadings = convertedReadings.reduce(function (acc, reading, indx, arr) {
    var datIndex = reading.datIndex,
        code = reading.code;

    if (acc[datIndex] === undefined) {
      acc[datIndex] = {};
    }

    if (code === 4) {
      if (acc[datIndex].lastReading !== undefined && acc[datIndex].lastReading.code !== 4) {
        acc.skips.push(acc[datIndex].lastReading);
        acc.skipGroups.push({
          skips: [acc[datIndex].lastReading]
        });
      }

      acc.skips.push(reading);

      if (acc.skipGroups.length === 0) {
        acc.skipGroups.push({
          skips: []
        });
      }

      acc.skipGroups[acc.skipGroups.length - 1].skips.push(reading);
    } else if (acc[datIndex].lastReading !== undefined && acc[datIndex].lastReading.code === 4) {
      acc.skips.push(reading);
      acc.skipGroups[acc.skipGroups.length - 1].skips.push(reading);
      var distance = (0, _calculateDistance.calculateDistance)(acc.skipGroups[acc.skipGroups.length - 1].skips.at(0).coordinates, acc.skipGroups[acc.skipGroups.length - 1].skips.at(-1).coordinates);
      acc.skipGroups[acc.skipGroups.length - 1].distance = distance;
    }

    acc[datIndex].lastReading = reading;
    return acc;
  }, {
    skips: [],
    skipGroups: [],
    distances: []
  });
  return {
    skips: skipReadings.skips,
    skipGroups: skipReadings.skipGroups
  }; // this code had issues with returning multiple skips

  /*const tempSkipReadings = convertedReadings.filter(
  	(gpsReading) => gpsReading.code === 4
  );
  	const interval = _findInterval(convertedReadings);
  const tempSkipReadingsWithAttributes = _setSkipAttributes(
  	tempSkipReadings,
  	interval
  );
  	return _addSkipFirstLastSegment(
  	tempSkipReadingsWithAttributes,
  	convertedReadings
  );*/
};

var _default = _deriveSkipReadings;
exports["default"] = _default;